import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import {
  FooterSection,
} from "./styles";

const Footer = ({ t }: any) => {
  return (
    <>
      <FooterSection>
        <Container>{"© 2022 French For Africa"}</Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
