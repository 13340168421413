import { SvgIconProps } from "../types";

export const SvgIcon = ({ src, width, height }: SvgIconProps) => (
  <img src={`/img/svg/${src}`} alt={src} width={width} height={height} />
);

export const ImageIcon = ({ src, width }: SvgIconProps) => (
  <img src={`/img/images/${src}`} alt={src} width={width} />
);

export const FullHeightIcon = ({ src, height }: SvgIconProps) => (
  <img src={`/img/images/${src}`} alt={src} height={height} />
);

